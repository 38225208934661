import DriversSection from "components/home/DriversSection"
import FutureSection from "components/home/FutureSection"
import HighlightsSection from "components/home/HighlightsSection"
import MainSection from "components/home/MainSection"
import React from "react"

const Home = (): JSX.Element => (
	<div>
		<MainSection />
		<FutureSection />
		<HighlightsSection />
		<DriversSection />
	</div>
)

export default Home
