import R from "client/routes/R"
import api from "client/utils/api"
import Button from "components/Button"
import React, { useState } from "react"
import isEmail from "validator/lib/isEmail"

const Subscribe = (): JSX.Element => {
	const [email, setEmail] = useState("")
	const [accepted, setAccepted] = useState(false)
	const [subscribeState, setSubscribeState] = useState({
		valid: true,
		message: "",
	})

	const subscribe = async (email: string): Promise<void> => {
		if (!accepted) {
			setSubscribeState({
				valid: false,
				message: "Please accept the terms and conditions",
			})

			return
		}

		const genericError = "Please provide a valid email."

		if (!isEmail(email)) {
			setSubscribeState({ valid: false, message: genericError })
			return
		}

		try {
			const response = await api.subscribe(email)

			if (response instanceof Error) {
				throw genericError
			}

			setEmail("")
			setAccepted(false)
			setSubscribeState({
				valid: true,
				message: "Please check your email to confirm your subscription.",
			})
		} catch (e) {
			setSubscribeState({
				valid: false,
				message: (e as Error)?.message ?? genericError,
			})
		}
	}

	return (
		<div className="flex flex-col justify-between max-w-[360px] mt-6 sm:mt-0">
			<div>
				<div className="flex">
					<input
						className="flex-1 px-4 w-full h-12 placeholder-gray-500 text-white bg-transparent border-t border-b border-l border-white"
						title="E-mail"
						placeholder="E-mail"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value)
						}}
					/>
					<Button
						title="SUBSCRIBE"
						minWidth="min-w-[4rem]"
						onClick={() => {
							if (subscribeState.message !== "") {
								setSubscribeState({ valid: true, message: "" })
							}

							subscribe(email)
						}}
					/>
				</div>

				{subscribeState.message == "" ? null : (
					<div
						className={`mt-2 text-xs ${
							subscribeState.valid ? "text-green-500" : "text-red-500"
						}`}
					>
						{subscribeState.message}
					</div>
				)}
			</div>

			<div className="flex mt-4 items-top">
				<div
					className="flex flex-shrink-0 justify-center items-center mr-4 w-4 h-4 border-white border-1"
					onClick={() => setAccepted(!accepted)}
				>
					{accepted ? <img src={require("./images/checkmark.svg")} /> : null}
				</div>
				<div className="-mt-1 text-sm text-gray-200">
					I have read the{" "}
					<a className="underline underline-offset-2" href={R.privacy}>
						Privacy Policy
					</a>{" "}
					and I agree to be informed by email about the latest news and events.
				</div>
			</div>
		</div>
	)
}

export default Subscribe
