import R from "client/routes/R"
import Button from "components/Button"
import Section from "components/Section"
import React from "react"
import { useNavigate } from "react-router"

const Element = ({
	title,
	body,
	image,
}: {
	title: string
	body: string
	image: string
}) => (
	<div className="pr-6 mt-8 lg:w-1/3 lg:mt-0">
		<div className="flex items-center lg:items-start">
			<img className="lg:hidden" src={image} />
			<h2 className="ml-6 lg:mt-10 lg:ml-0">{title}</h2>
		</div>
		<p className="mt-4">{body}</p>
	</div>
)

const MethodologySection = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<Section>
			<h2>Project methodology</h2>

			<div className="mt-0 lg:mt-20">
				<div className="flex invisible w-full lg:visible">
					<div className="flex justify-between items-center w-1/3">
						<img src={require("./images/1.svg")} />
						<div className="w-full border-t border-theme" />
					</div>

					<div className="flex relative items-center w-1/3">
						<img src={require("./images/2.svg")} />
						<div className="w-full border-t border-theme" />
					</div>

					<div className="flex relative items-center w-1/3">
						<img src={require("./images/3.svg")} />
						<div className="w-full border-t border-theme" />
					</div>
				</div>

				<div className="md:flex">
					<Element
						title="9 High-level drivers of change"
						body="Respondents from different regions, sectors and roles were presented, through an online survey, nine high-level drivers of change happening in arts and culture globally."
						image={require("./images/1.svg")}
					/>
					<Element
						title="Extrapolate multiple possible futures"
						body="They were then asked to select one driver, extrapolate impacts of that driver through several steps, and describe future vignettes where several key impacts combine. This exercise was open during June and July 2021, and invitees were offered the opportunity to go through several rounds of the exercise to explore multiple possible futures."
						image={require("./images/2.svg")}
					/>
					<Element
						title="Analysis on causes and impacts"
						body="Changeist analysed the causes and impacts mapped in the responses, their relationships and outcomes, and developed three inductive scenarios from these inputs. The scenario narratives represent the dominant views of the group as a whole."
						image={require("./images/3.svg")}
					/>
				</div>
			</div>

			<div className="justify-between items-center sm:flex mt-18">
				<p className="text-white text-opacity-60 sm:w-2/3">
					The scenarios presented here are not meant to be forecasts as much as
					provocations that interweave the collective opinions and insights of
					the global participant group. They are possible futures as
					collectively voiced by contributors, and the narratives that follow
					connect the dots of data in narrative forms that can be more easily
					explored.
				</p>
				<div className="mt-8 sm:mt-0">
					<Button
						title="GO TO SCENARIOS"
						onClick={() => navigate(R.scenarios)}
					/>
				</div>
			</div>
		</Section>
	)
}

export default MethodologySection
