import R from "client/routes/R"
import Content from "components/Content"
import Line from "components/Line"
import React from "react"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"

const MobileElement = ({
	text,
	path,
	onClick,
}: {
	text: string
	path: string
	onClick: () => void
}) => {
	const currentPath = useLocation().pathname

	return (
		<div
			className={`flex cursor-pointer border-l-4 ml-8 z-40 ${
				(currentPath.startsWith(`${path}`) && path !== "/") ||
				currentPath === path
					? "text-theme border-current font-medium"
					: "border-transparent font-light"
			}`}
			onClick={onClick}
		>
			<div className="my-4 ml-8 text-3xl uppercase">{text}</div>
		</div>
	)
}

const Element = ({ text, path }: { text: string; path: string }) => {
	const currentPath = useLocation().pathname

	return (
		<div
			className={`flex border-b-2 items-center h-full z-1 ${
				(currentPath.startsWith(`${path}`) && path !== "/") ||
				currentPath === path
					? "text-theme border-current font-medium"
					: "border-transparent"
			}`}
		>
			<Link className="text-sm 2xl:px-5 xl:px-2" to={path}>
				{text}
			</Link>
		</div>
	)
}

const Elements = (): JSX.Element => {
	return (
		<div className="hidden justify-between w-1/2 h-full uppercase md:tracking-wide lg:w-1/3 sm:flex">
			<div className="invisible lg:visible">
				<Line direction="vertical" />
			</div>

			<Element text="Home" path={R.home} />
			<Element text="Drivers" path={R.drivers} />
			<Element text="Scenarios" path={R.scenarios} />
			<Element text="About" path={R.about} />

			<div>
				<Line direction="vertical" />
			</div>
		</div>
	)
}

const MobileNavigationButton = ({
	isMenuOpen,
	setIsMenuOpen,
}: MobileMenuProps): JSX.Element => (
	<div
		className="flex justify-end w-12 cursor-pointer min-h-[48px] sm:hidden z-30"
		onClick={() => setIsMenuOpen(!isMenuOpen)}
	>
		{isMenuOpen ? (
			<img src={require("./images/x.svg")} />
		) : (
			<img src={require("./images/hamburger.svg")} />
		)}

		<div>
			<Line className="z-20" direction="vertical" />
		</div>
	</div>
)

const MobileNavigation = ({
	isMenuOpen,
	setIsMenuOpen,
	dark,
}: { dark: boolean } & MobileMenuProps): JSX.Element => {
	const navigate = useNavigate()

	return (
		<div
			className={`fixed top-24 bottom-0 left-0 right-0 sm:hidden h-screen ${
				dark ? "bg-dark" : "bg-black"
			} z-1 ${isMenuOpen ? "visible" : "hidden"}`}
		>
			<div className="flex flex-col justify-center h-full animate-appearFromBelow">
				<div className="-mt-12">
					<MobileElement
						text="Home"
						path={R.home}
						onClick={() => {
							navigate(R.home)
							setIsMenuOpen(false)
						}}
					/>
					<MobileElement
						text="Drivers"
						path={R.drivers}
						onClick={() => {
							navigate(R.drivers)
							setIsMenuOpen(false)
						}}
					/>
					<MobileElement
						text="Scenarios"
						path={R.scenarios}
						onClick={() => {
							navigate(R.scenarios)
							setIsMenuOpen(false)
						}}
					/>
					<MobileElement
						text="About"
						path={R.about}
						onClick={() => {
							navigate(R.about)
							setIsMenuOpen(false)
						}}
					/>
				</div>
			</div>
		</div>
	)
}

const Navigation = ({
	isMenuOpen,
	setIsMenuOpen,
}: MobileMenuProps): JSX.Element => {
	const location = useLocation()
	const hasDarkBackground = [
		R.drivers,
		R.stages,
		R.communities,
		R.multiverses,
		R.scenarios,
		R.about,
	]
		.map((r) => r as string)
		.includes(location.pathname)

	return (
		<div>
			<Line
				className={`${isMenuOpen ? "top-24 z-20" : "top-[95px]"}`}
				direction="horizontal"
			/>

			{isMenuOpen ? (
				<div
					className={`absolute top-0 right-0 left-0 h-24 ${
						hasDarkBackground ? "bg-dark" : "bg-black"
					}`}
				/>
			) : null}

			<Content background={hasDarkBackground ? "bg-dark" : ""}>
				<Line className={isMenuOpen ? "z-20" : ""} direction="vertical" />

				<div className="flex items-center h-24">
					<div className="w-full h-full text-xl xl:text-2xl">
						<div className="flex justify-between items-center w-full h-full">
							<div
								className={`absolute h-full border-l-4 ${
									isMenuOpen ? "z-20" : ""
								}`}
							/>
							<div className="flex sm:w-1/2 lg:w-2/3">
								<div className="pl-5 w-full lg:w-1/2">
									Future of Arts & Culture
								</div>
								<div className="invisible w-0.25 lg:w-1/2 md:visible">
									<Line direction="vertical" />
								</div>
							</div>
							<Elements />
							<MobileNavigationButton
								isMenuOpen={isMenuOpen}
								setIsMenuOpen={setIsMenuOpen}
							/>
						</div>
					</div>
				</div>

				{/* <div className="absolute right-2 md:right-4 lg:right-7 xl:right-12">
					<img src={require("./images/logo.svg")} />
				</div> */}

				<MobileNavigation
					dark={hasDarkBackground}
					isMenuOpen={isMenuOpen}
					setIsMenuOpen={setIsMenuOpen}
				/>
			</Content>
		</div>
	)
}

export type MobileMenuProps = {
	isMenuOpen: boolean
	setIsMenuOpen: (isMenuOpen: boolean) => void
}

export default Navigation
