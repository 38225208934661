import React from "react"

const Event = (): JSX.Element => (
	<div className="flex overflow-hidden relative flex-grow justify-center">
		<iframe
			className="event-iframe"
			src="https://www.youtube.com/embed/n45EJ1bBh5M"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			sandbox="allow-same-origin allow-scripts"
			frameBorder={0}
			allowFullScreen
			title="Future of Arts and Culture Report"
		/>
	</div>
)

export default Event
