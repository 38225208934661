// eslint-disable-next-line import/no-unassigned-import
import "shared/envSetup"
import App from "client/App"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"

// Use `.render` for dev; not sure why it spits out an error otherwise.
// In development there is no actual server render, so there's nothing to hydrate?
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

const base = (
	<BrowserRouter>
		<App />
	</BrowserRouter>
)

const app =
	!process.isProd && module.hot ? (
		base
	) : (
		<React.StrictMode>{base}</React.StrictMode>
	)

renderMethod(app, document.getElementById("root"))

if (!process.isProd && module.hot) {
	module.hot.accept()
}
