import Router from "client/routes/Router"
import { updateNavigate } from "client/utils/api"
import Helmet from "client/utils/Helmet"
import Navigation from "components/Navigation"
import Footer from "pages/Footer"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useNavigationType } from "react-router-dom"
// eslint-disable-next-line import/no-unassigned-import
import "./App.css"

interface Props {
	ssrLocation?: string
}

const App = ({ ssrLocation }: Props): JSX.Element => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [path, setPath] = useState<string>(ssrLocation ?? "")
	const navigation = useNavigationType()
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		if (typeof window === "undefined") {
			return
		}

		updateNavigate(navigate)
	}, [navigate])

	useEffect(() => {
		const isSamePath = path === location.pathname
		setPath(location.pathname)

		if (
			typeof window === "undefined" ||
			navigation !== "PUSH" ||
			isSamePath ||
			path == ""
		) {
			return
		}

		window.scrollTo(0, 0)
	}, [navigation, location.pathname, path])

	return (
		<>
			<Helmet ssrLocation={ssrLocation} />
			<Navigation isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
			<div className={isMenuOpen ? "fixed -z-1" : ""}>
				<Router />
			</div>
			<Footer />
		</>
	)
}

export default App
