import api from "client/utils/api"
import Button from "components/Button"
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

const ConfirmSubscription = (): JSX.Element => {
	const location = useLocation()
	const [isLoading, setLoading] = useState(true)
	const [error, setError] = useState("")

	useEffect(() => {
		const token = new URLSearchParams(location.search).get("token")
		const genericError =
			"Confirmation failed, please try again or contact support."

		const displayError = (message: string) => {
			setLoading(false)
			setError(message)
		}

		if (token == null) {
			displayError(genericError)
			return
		}

		const fetch = async () => {
			try {
				const response = await api.confirmSubscription(token)

				if (response instanceof Error) {
					throw genericError
				}

				setLoading(false)
				setError("")
			} catch (e) {
				displayError((e as Error)?.message ?? genericError)
			}
		}

		fetch()
	}, [location.search])

	return (
		<div className="flex relative flex-col justify-center items-center py-0 mx-auto max-w-2xl min-h-screen text-3xl font-bold text-center">
			{isLoading ? (
				<h1>Confirming subscription...</h1>
			) : (
				<h1>
					{error == "" ? (
						<div className="flex flex-col items-center">
							<p className="mb-12">
								Success! You will now receive the latest news and events.
							</p>
							<Link to="/">
								{/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
								<Button title="GO BACK HOME" onClick={() => {}} />
							</Link>
						</div>
					) : (
						error
					)}
				</h1>
			)}
		</div>
	)
}

export default ConfirmSubscription
