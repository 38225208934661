import R from "client/routes/R"
import Section from "components/Section"
import TextButton from "components/TextButton"
import React from "react"
import { useNavigate } from "react-router"

const DriversSection = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<Section dark>
			<div className="lg:flex">
				<div className="lg:w-1/3 lg:pr-20">
					<h2>Ranking of Root Issues by Frequency of First Selection</h2>
					<div className="mt-10">
						<p>
							Visual representation of the root issues highlighted by the
							frequency with which they were selected first by respondents.
						</p>
					</div>
					<TextButton
						className="hidden mt-18 lg:flex"
						icon="plus"
						text="Go to drivers"
						onClick={() => navigate(R.drivers)}
					/>
				</div>

				<img
					className="mx-auto mt-12 w-4/5 lg:mt-8 lg:w-2/3"
					src={require("./images/drivers.svg")}
				/>

				<div>
					<TextButton
						className="mt-18 lg:hidden"
						icon="plus"
						text="Go to drivers"
						onClick={() => navigate(R.drivers)}
					/>
				</div>
			</div>
		</Section>
	)
}

export default DriversSection
