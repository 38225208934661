import R from "client/routes/R"
import Section from "components/Section"
import TextButton from "components/TextButton"
import React from "react"
import { useNavigate } from "react-router"

const FutureSection = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<Section dark>
			<div className="lg:flex">
				<div className="lg:w-1/3">
					<h2 className="pr-20">What is the future of arts and culture?</h2>
				</div>

				<div className="mt-10 lg:w-2/3 lg:mt-0">
					<h1>
						Future of Arts and Culture is a qualitative probe into the future of
						arts and culture globally.
					</h1>
					<p className="pt-10">
						It examines emerging forces, drivers of change and the possible
						impacts that may reshape the cultural sector in the coming decade.
						The project was created as a collaboration among futurists, sector
						experts and stakeholders.
					</p>
					<TextButton
						className="mt-10"
						icon="plus"
						text="Find out more"
						onClick={() => navigate(R.scenarios)}
					/>
				</div>
			</div>
		</Section>
	)
}

export default FutureSection
