import Plus from "components/images/Plus"
import React from "react"

type IconType = "plus" | "chevron-down" | "download"

const ChevronDown = () => (
	<svg width="26" height="15" className="stroke-current">
		<path d="M1 1l12 12.5L25 1" />
	</svg>
)

const Icon = (type: IconType) => {
	switch (type) {
		case "plus":
			return <Plus />
		case "chevron-down":
			return <ChevronDown />
		case "download":
			return <img src={require("./images/download.svg")} />
		default:
			return null
	}
}

const TextButton = ({
	className,
	icon,
	iconSide = "left",
	alignment,
	text,
	onClick,
}: {
	className?: string
	icon: IconType
	iconSide?: "left" | "right"
	alignment?: "center" | "bottom"
	text: string
	onClick: () => void
}): JSX.Element => (
	<button
		className={`flex text-theme ${className ?? ""} ${
			alignment === "center" ? "" : "items-end"
		}`}
		onClick={onClick}
	>
		{iconSide === "left" ? Icon(icon) : null}
		<div
			className={`font-bold text-xs tracking-2.4 uppercase ${
				iconSide === "left" ? "pl-4" : "pr-4"
			}`}
		>
			{text}
		</div>
		{iconSide === "right" ? Icon(icon) : null}
	</button>
)

export default TextButton
