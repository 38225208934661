import Section from "components/Section"
import React from "react"

const Highlight = ({
	title,
	body,
}: {
	title: string
	body: React.ReactChild
}): JSX.Element => (
	<>
		<div className="flex pr-6">
			<div className="h-[142px] border-l-4 border-theme" />

			<div className="pl-4">
				<div className="font-bold leading-3 uppercase text-theme">{title}</div>
				<div className="pt-4">{body}</div>
			</div>
		</div>
	</>
)

const HighlightsSection = (): JSX.Element => (
	<Section>
		<div className="grid grid-cols-1 gap-y-10 md:grid-cols-2 lg:grid-cols-3">
			<h2 className="">Highlights</h2>
			<Highlight
				title="Three major narratives"
				body={
					<ul>
						Through the research and sensemaking process, three major narratives
						took shape:
						<li>
							arts and culture is increasingly profit-driven, commercially
							measured, and focused on experiences{" "}
						</li>
						<li>
							arts and culture take a more active role in supporting social
							innovation, inclusion and diversity.
						</li>
						<li>
							arts and culture shifts to many independent digital platforms.
						</li>
					</ul>
				}
			/>
			<Highlight
				title="A future of smart adaptation"
				body={
					<p>
						The overarching theme was one of pragmatism and openness to
						“rigorous, smart adaptation,” finding ways to walk a line between
						embracing new ways of working, creating and sharing through
						innovation, while taking a front-stage role in expanding diversity
						and social connectedness.
					</p>
				}
			/>
			<Highlight
				title="The metaverse"
				body={
					<p>
						Audiences will continue to move online, regardless of the short-term
						fluctuations of COVID. “The Metaverse” is coming, but it must be
						inclusive and not just open to those with the best technology,
						networks, and investment.
					</p>
				}
			/>
			<Highlight
				title="New models"
				body={
					<p>
						There is a recognition that historic models of funding and support
						are not suitable for the future, and that new models are possible,
						and already emerging. These include new ownership models, new
						economic tools, and even taxation models.
					</p>
				}
			/>
			<Highlight
				title={`Rethinking "publics"`}
				body={
					<p>
						Arts and culture need to rethink its conception of “publics” as well
						as its role as a public good that can actively seed new futures.
					</p>
				}
			/>
		</div>
	</Section>
)

export default HighlightsSection
