import api from "client/utils/api"
import Button from "components/Button"
import React, { useState } from "react"
import { useLocation } from "react-router"

const Unsubscribe = (): JSX.Element => {
	const location = useLocation()
	const [unsubscribeState, setUnsubscribeState] = useState({
		valid: true,
		message: "",
	})

	const unsubscribe = async (): Promise<void> => {
		const token = new URLSearchParams(location.search).get("token")
		const genericError =
			"Something went wrong, please try again or contact support."

		if (token == null) {
			setUnsubscribeState({
				valid: false,
				message: genericError,
			})
			return
		}

		try {
			const response = await api.unsubscribe(token)

			if (response instanceof Error) {
				throw genericError
			}

			setUnsubscribeState({
				valid: true,
				message:
					"Success! You will no longer receive the latest news and events.",
			})
		} catch (e) {
			setUnsubscribeState({
				valid: false,
				message: (e as Error)?.message ?? genericError,
			})
		}
	}

	const input = (
		<div className="flex flex-col items-center">
			<h4 className="mb-4">
				Are you sure you don't want to receive the latest news and events from
				the Future of Arts & Culture study?
			</h4>
			{/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
			<Button
				title="YES, UNSUBSCRIBE"
				onClick={() => {
					unsubscribe()
				}}
			/>
		</div>
	)

	return (
		<div className="flex relative flex-col justify-center items-center py-0 mx-auto max-w-3xl min-h-screen text-3xl font-bold text-center">
			{!unsubscribeState.valid || unsubscribeState.message == "" ? input : null}

			{unsubscribeState.message == "" ? null : unsubscribeState.valid ? (
				<div className="flex relative flex-col justify-center items-center py-0 mx-auto max-w-2xl min-h-screen text-3xl font-bold text-center">
					<h1>{unsubscribeState.message}</h1>
				</div>
			) : (
				<div
					className={`mt-2 text-xs ${
						unsubscribeState.valid ? "text-green-500" : "text-red-500"
					}`}
				>
					{unsubscribeState.message}
				</div>
			)}
		</div>
	)
}

export default Unsubscribe
