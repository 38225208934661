import Event from "client/pages/Event"
import Home from "client/pages/Home"
import R from "client/routes/R"
import { scenarios } from "components/scenarios/Scenario"
import About from "pages/About"
import ConfirmSubscription from "pages/ConfirmSubscription"
import Drivers from "pages/Drivers"
// import Graph from "pages/graph/Graph"
import Legal from "pages/Legal"
import Scenarios from "pages/Scenarios"
import ScenarioView from "pages/ScenarioView"
import Unsubscribe from "pages/Unsubscribe"
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

const Router = (): JSX.Element => (
	<Routes>
		<Route path={R.home} element={<Home />} />
		{/* <Route path={R.graph} element={<Graph />} /> */}
		<Route path={R.event} element={<Event />} />
		<Route path={R.drivers} element={<Drivers />} />
		<Route path={R.scenarios} element={<Scenarios />} />
		<Route path={R.unsubscribe} element={<Unsubscribe />} />
		<Route path={R.confirmSubscription} element={<ConfirmSubscription />} />
		<Route path={R.stages} element={<ScenarioView scenario={scenarios[0]} />} />
		<Route
			path={R.communities}
			element={<ScenarioView scenario={scenarios[1]} />}
		/>
		<Route
			path={R.multiverses}
			element={<ScenarioView scenario={scenarios[2]} />}
		/>
		<Route path={`${R.scenarios}/*`} element={<Navigate to={R.scenarios} />} />
		<Route path={R.about} element={<About />} />
		<Route path={R.legal} element={<Legal />} />
		<Route path="*" element={<Navigate to={R.home} />} />
	</Routes>
)

export default Router
