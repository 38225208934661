/* eslint-disable @typescript-eslint/ban-ts-comment */
process.isTest = process.env.NODE_ENV === "test"
process.isProd = process.env.NODE_ENV === "production"
process.isDev = !process.isProd && !process.isTest

// @ts-ignore
Date.hour = 3_600_000
// @ts-ignore
Date.day = 86_400_000

export {}
