import R from "client/routes/R"
import Content from "components/Content"
import Copyright from "components/footer/Copyright"
import Subscribe from "components/footer/Subscribe"
import Line from "components/Line"
import TextButton from "components/TextButton"
import React from "react"
import { Link, useLocation } from "react-router-dom"

const Footer = (): JSX.Element => {
	const location = useLocation()

	return (
		<Content>
			{location.pathname === R.event ? (
				<Line className="-right-24 -left-24" direction="horizontal" />
			) : null}

			<div className="flex pt-20 w-full">
				<div className="flex flex-col justify-between pl-4 w-full text-white text-opacity-50 border-l-8 border-current">
					<div className="flex flex-wrap justify-between">
						<p className="text-2xl">Future of Arts & Culture</p>
						<Subscribe />
					</div>

					<a className="mt-8 sm:hidden" href="/files/FoAaC-Presentation.pdf">
						<TextButton
							icon="download"
							iconSide="right"
							alignment="bottom"
							text="Download PDF"
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClick={() => {}}
						/>
					</a>

					<div className="flex justify-between mt-10 w-full sm:mt-16">
						<div className="flex flex-row-reverse flex-wrap-reverse justify-end items-end text-sm uppercase sm:flex-wrap sm:flex-row">
							<Link className="mr-4 md:mr-12" to={R.about} title="About">
								About
							</Link>
							<a
								className="mr-4 md:mr-12"
								href="mailto:contact@futureofartsandculture.org"
								title="Contact"
							>
								Contact
							</a>
							<a
								className="mr-4 md:mr-12"
								href={R.privacy}
								title="Privacy Policy"
							>
								Privacy Policy
							</a>
							<a className="mr-4 md:mr-12" href={R.legal} title="Legal">
								Legal
							</a>
						</div>

						<a className="hidden sm:block" href={R.presentationPDF}>
							<TextButton
								icon="download"
								iconSide="right"
								alignment="bottom"
								text="Download PDF"
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								onClick={() => {}}
							/>
						</a>
					</div>
				</div>
			</div>

			<Copyright />
		</Content>
	)
}

export default Footer
