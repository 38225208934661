import React from "react"

const Button = ({
	title,
	minWidth,
	className,
	onClick,
}: {
	title: string
	minWidth?: string
	className?: string
	onClick: () => void
}): JSX.Element => (
	<div
		className={`flex justify-center items-center px-6 h-12 bg-theme text-[12px] font-bold tracking-widest cursor-pointer ${
			minWidth ?? "min-w-[10rem]"
		} ${className ?? ""}`}
		onClick={() => onClick()}
	>
		{title}
	</div>
)

export default Button
