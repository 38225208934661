import React from "react"

const Plus = ({ w = 16 }: { w?: number }) => (
	<svg
		width={w}
		height={w}
		viewBox="0 0 16 16"
		className="stroke-current text-theme group-hover:text-black"
	>
		<path d="M8 0V16" />
		<path d="M16 8L-2.98023e-07 8" />
	</svg>
)

export default Plus
