import Helmet from "client/utils/Helmet"
import Section from "components/Section"
import React from "react"

const Legal = (): JSX.Element => (
	<Section>
		<Helmet title="Legal" />
		<div className="flex">
			<h1 className="w-1/3">LEGAL</h1>
			<div className="w-2/3 text-2xl">
				<p className="font-bold">Last updated 19 May 2021.</p>
				<p className="mt-6">
					These terms and conditions (Terms) govern your use of each of our
					websites as set out below (our site). Please read them carefully
					before using this site.{" "}
				</p>

				<p className="mt-14 font-bold">WHO WE ARE AND HOW TO CONTACT US</p>
				<p>
					www.thermegroup.com, www.thermemanchester.co.uk, and
					www.thermecanada.com are sites operated by Therme Group RHTG AG, a
					public limited company with its registered office address at
					Wienerbergstraße 51, 1120 Vienna, Austria, with company number FN
					364773 g (“Therme Group”, “we” or “us”).
				</p>
				<p>
					To contact us, please use the details in the Contact us section below.
				</p>

				<p className="mt-14 font-bold">
					BY USING OUR SITE, YOU ACCEPT THESE TERMS
				</p>
				<p>
					By using our site, you confirm that you accept these Terms and that
					you agree to comply with them. If you do not agree with these Terms,
					you must stop using our site.
				</p>
				<p className="mt-6">
					We recommend that you print a copy of these Terms for future
					reference.{" "}
				</p>

				<p className="mt-14 font-bold">OTHER TERMS THAT MAY APPLY TO YOU</p>
				<p>
					<ul>
						When you use our site, these additional policies also apply:
						<li>Therme Group’s Privacy Policy</li>
						<li>
							If you are based in Canada and use the www.thermecanada.com site:
							our Therme Canada Privacy Policy applies to you.
						</li>
						<li>Our Cookie Policy.</li>
					</ul>
				</p>

				<p className="mt-14 font-bold">WE MAY MAKE CHANGES TO THESE TERMS</p>
				<p>
					We amend these Terms from time to time. Please check back to ensure
					you understand the Terms that apply at that time.
				</p>

				<p className="mt-14 font-bold">WE MAY SUSPEND OR WITHDRAW OUR SITE</p>
				<p>
					Our site is made available free of charge. <br /> We do not guarantee
					that our site, or any content on it, will always be available or be
					uninterrupted. We may suspend or withdraw or restrict the availability
					of all or any part of our site for business and operational reasons.
					We will try to give you reasonable notice of any suspension or
					withdrawal. <br /> You are also responsible for ensuring that all
					persons who access our site through your internet connection are aware
					of these Terms and other applicable terms and conditions, and that
					they comply with them.
				</p>

				<p className="mt-14 font-bold">
					WE MAY TRANSFER THIS AGREEMENT TO SOMEONE ELSE
				</p>
				<p>
					We may transfer our rights and obligations under these Terms to
					another organisation. We will always tell you in writing if this
					happens and we will ensure that the transfer will not affect your
					rights under the contract.{" "}
				</p>

				<p className="mt-14 font-bold">PROHIBITED USES OF OUR SITE</p>
				<ul>
					You may only use our site for lawful purposes. You may not use our
					site:
					<li>
						In any way that breaches any applicable local, national or
						international law or regulation.
					</li>
					<li>
						In any way that is unlawful or fraudulent or has any unlawful or
						fraudulent purpose or effect.
					</li>
					<li>
						For the purpose of harming or attempting to harm minors in any way.
					</li>
					<li>To bully, insult, intimidate or humiliate any person.</li>
				</ul>
			</div>
		</div>
	</Section>
)

export default Legal
