export default {
	home: "/",
	graph: "/interactive-graph",
	event: "/event",
	drivers: "/drivers",
	scenarios: "/scenarios",
	about: "/about",
	contact: "/contact",
	confirmSubscription: "/confirm-subscription",
	unsubscribe: "/unsubscribe",
	privacy:
		"https://www.thermegroup.com/wp-content/uploads/2020/08/Therme-Marketing-Privacy-Notice.pdf",
	legal:
		"https://www.thermegroup.com/wp-content/uploads/2021/11/2021.10.25-Therme-Group-Terms-of-Website-Use-.pdf",
	stages: "/scenarios/stages",
	communities: "/scenarios/communities",
	multiverses: "/scenarios/multiverses",
	presentationPDF: "/files/FoAaC-Presentation.pdf",
} as const
