import R from "client/routes/R"
import Plus from "components/images/Plus"
import AboutSection from "components/scenarios/AboutSection"
import ScrollForMore from "components/ScrollForMore"
import Section from "components/Section"
import React from "react"
import { useNavigate } from "react-router"

const Element = ({
	title,
	subtitle,
	onClick,
}: {
	title: React.ReactChild
	subtitle: string
	onClick: () => void
}) => (
	<div className="mx-auto w-96 max-w-full lg:w-1/3">
		<div
			className="flex justify-center mt-24 mr-6 h-56 bg-opacity-20 border transition-colors duration-300 ease-in-out cursor-pointer md:h-54 xl:h-60 border-theme bg-theme hover:bg-opacity-100 group"
			onClick={onClick}
		>
			<div className="mx-6">
				<div className="flex justify-end mt-6">
					<Plus w={24} />
				</div>

				<div className="text-2xl lg:text-[28px] xl:text-4xl 2xl:text-[40px] font-light text-theme group-hover:text-black h-18 lg:h-22 xl:h-[90px] leading-8 xl:leading-10 mt-4">
					{title}
				</div>
				<div>{subtitle}</div>
			</div>
		</div>
	</div>
)

const MainSection = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<Section dark>
			<div className="absolute -mt-36 -ml-28 w-full h-full bg-center bg-no-repeat opacity-30 -z-1 bg-scenarios" />

			<h1 className="w-2/3">Scenarios</h1>

			<AboutSection />

			<div className="items-center lg:flex">
				<Element
					title={
						<div>
							Spectaculars <br />
							and Small Stages
						</div>
					}
					subtitle="Profitability overtakes cultural impact"
					onClick={() => navigate(R.stages)}
				/>
				<Element
					title={
						<div>
							Global Network <br />
							of Communities
						</div>
					}
					subtitle="Smaller, diverse institutions come together"
					onClick={() => navigate(R.communities)}
				/>
				<Element
					title={
						<div>
							Platforms <br />
							and Multiverses
						</div>
					}
					subtitle="Digital commerce drivers culture"
					onClick={() => navigate(R.multiverses)}
				/>
			</div>

			<div className="flex justify-end mt-20">
				<ScrollForMore />
			</div>
		</Section>
	)
}

export default MainSection
