import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import storage from "client/utils/storage"
import { NavigateFunction } from "react-router"
import { TokenResponse } from "v1/accounts/_auth/refreshJWT"

type AxiosMethodWithParams = <T, R = AxiosResponse<T>>(
	url: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: any,
	config?: AxiosRequestConfig
) => Promise<R>

type AxiosMethod = <T, R = AxiosResponse<T>>(
	url: string,
	config?: AxiosRequestConfig
) => Promise<R>

type APIVersion = "v1" | "v2"

const instance = axios.create()
// We create this here, so we can use it to redirect on logout.
let navigate: NavigateFunction | undefined

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const performWithParams = <T>(
	method: AxiosMethodWithParams,
	path: string,
	params: unknown,
	version: APIVersion
) => method<T>(`/api/${version}/${path}`, params)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const perform = <T>(method: AxiosMethod, path: string, version: APIVersion) =>
	method<T>(`/api/${version}/${path}`)

const logOut = (): void => {
	storage.removeJWT()
	navigate?.("/")
}

const refreshJWT = (
	version: APIVersion = "v1"
): Promise<AxiosResponse<TokenResponse>> =>
	perform(instance.put, "users/refresh-jwt", version)

const confirmSubscription = (
	token: string,
	version: APIVersion = "v1"
): Promise<AxiosResponse> =>
	performWithParams(instance.put, "subscribe", { token }, version)

const subscribe = (
	email: string,
	version: APIVersion = "v1"
): Promise<AxiosResponse> =>
	performWithParams(instance.post, "subscribe", { email }, version)

const unsubscribe = (
	token: string,
	version: APIVersion = "v1"
): Promise<AxiosResponse> =>
	performWithParams(instance.delete, `subscribe/${token}`, {}, version)

const api = {
	users: {
		refreshJWT,
		logOut,
	},
	confirmSubscription,
	subscribe,
	unsubscribe,
}

export const updateNavigate = (_navigate: NavigateFunction): void => {
	navigate = _navigate
}

export default api
