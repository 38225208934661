import React from "react"
import { Helmet as ReactHelmet } from "react-helmet"

interface Props {
	title: string
	description: string
	image?: string
	ssrLocation?: string
}

const Helmet = ({
	title,
	description,
	image,
	ssrLocation,
}: Props): JSX.Element => {
	// Not really sure when this happens and why...
	if (typeof window === "undefined" && ssrLocation == null) {
		return (
			<ReactHelmet>
				<title>{title}</title>
				<meta name="description" content={description} />
			</ReactHelmet>
		)
	}

	// We use the `window`'s location when rendering on the client.
	// Used when we need the full URL, like canonical or OG tags.
	const location = ssrLocation ?? window.location.href

	return (
		<ReactHelmet>
			<title>{title}</title>
			<link rel="canonical" href={location} />
			{/* Metadata */}
			<meta name="description" content={description} />
			{/* OG */}
			<meta property="og:title" content={title} />
			{image == null ? null : <meta property="og:image" content={image} />}
			<meta property="og:description" content={description} />
			<meta property="og:url" content={location} />
			<meta property="og:site_name" content={title} />
			<meta property="og:type" content="website" />
		</ReactHelmet>
	)
}

Helmet.defaultProps = {
	title: "Future of Arts & Culture",
	description: "The future of arts and culture",
}

export default Helmet
