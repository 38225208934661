import Section from "components/Section"
import React from "react"

const questions = [
	"Are these types of scenarios or their underlying drivers emerging in our communities, and if so, how are we responding?",
	"What risks or opportunities emerge from these scenarios for us?",
	"What new talent, tools, skills, networks, or resources do we need to face them?",
	"How might broader or more narrow audiences impact how we support our communities?",
	"How might radical shifts in funding levels and sources change what we do and how we do it?",
]

const QuestionsSection = (): JSX.Element => (
	<Section>
		<div className="lg:flex">
			<div className="lg:w-1/3" />
			<div className="lg:w-2/3">
				<h2>
					Questions for arts and culture{" "}
					<br className="2xs:hidden xs:inline sm:inline smmd:hidden md:inline mdlg:hidden lg:inline 2xl:hidden" />
					organisations to consider
				</h2>

				<div className="mt-10">
					{questions.map((q, i) => {
						return (
							<div className="flex items-center mt-2" key={i}>
								<div className="font-thin text-[64px] text-theme">
									{(i + 1).toString().padStart(2, "0")}
								</div>
								<p className="ml-6 text-2xl">{q}</p>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	</Section>
)

export default QuestionsSection
