import keyDrivers from "components/drivers/keyDrivers"
import Section from "components/Section"
import React, { useState } from "react"

const image = (index: number) => {
	switch (index) {
		case 0:
			return require("./images/keyDriver-1.svg")
		case 1:
			return require("./images/keyDriver-2.svg")
		case 2:
			return require("./images/keyDriver-3.svg")
		case 3:
			return require("./images/keyDriver-4.svg")
		case 4:
			return require("./images/keyDriver-5.svg")
		case 5:
			return require("./images/keyDriver-6.svg")
		case 6:
			return require("./images/keyDriver-7.svg")
		case 7:
			return require("./images/keyDriver-8.svg")
		case 8:
			return require("./images/keyDriver-9.svg")
		case 9:
			return require("./images/keyDriver-10.svg")
		case 10:
			return require("./images/keyDriver-11.svg")
		case 11:
			return require("./images/keyDriver-12.svg")
		case 12:
			return require("./images/keyDriver-13.svg")
		default:
			return undefined
	}
}

const KeyDriversSection = (): JSX.Element => {
	const [state, setState] = useState(0)

	const driver = (i: number, title: string) => (
		<div
			key={i}
			className={`cursor-pointer lg:px-6 ${i % 3 === 1 ? "px-2 sm:px-4" : ""}`}
			onClick={() => setState(i)}
		>
			<div
				className={`flex justify-between h-full items-center py-3 text-xs lg:text-sm font-medium border-t border-white ${
					i === keyDrivers.length - 3 ? "border-b lg:border-b-0" : ""
				} ${i === keyDrivers.length - 2 ? "border-b lg:border-b-0" : ""} ${
					i === keyDrivers.length - 1 ? "border-b" : ""
				} ${state === i ? "text-theme" : "toned-down"}`}
			>
				<div className="pr-2 lg:pr-0">{title}</div>
				<div>{`${i + 1}`.padStart(2, "0")}</div>
			</div>
		</div>
	)

	return (
		<Section dark>
			<div className="lg:hidden">
				<h1>Key Drivers and Impact</h1>
				<h4 className="mt-2">
					Exploration of key drivers and possible impacts that may reshape the
					cultural sector
				</h4>
			</div>

			<div className="grid grid-cols-3 my-8 lg:hidden">
				{keyDrivers.map((d, i) => {
					return driver(i, d.title)
				})}
			</div>

			<div className="flex items-end mt-4 lg:mt-0">
				<div className="lg:w-1/3">
					<div className="hidden pr-4 lg:block">
						<h1 className="lg:text-4xl xl:text-5xl">Key Drivers and Impact</h1>
						<h4 className="mt-2">
							Exploration of key drivers and possible impacts that may reshape
							the cultural sector
						</h4>
					</div>

					<div className="hidden lg:block mt-10 h-[27rem] lg:mt-12 xl:mt-14 lg:h-85 custom-scrollbar">
						<div className="inverse">
							{keyDrivers.map((d, i) => {
								return driver(i, d.title)
							})}
						</div>
					</div>
				</div>

				<div className="pt-4 lg:w-2/3 h-[30rem] md:h-[35rem] lg:h-[35rem] xl:h-[36rem]">
					<div className="flex flex-col justify-between px-8 h-full border-l-4 border-theme">
						<h2>
							{(state + 1).toString().padStart(2, "0")}.{" "}
							{keyDrivers[state].title}
						</h2>
						<div className="flex flex-col flex-1 justify-between mt-6">
							<p>{keyDrivers[state].body}</p>
							<img className="-ml-0.5 w-full" src={image(state)} />
						</div>
					</div>
				</div>
			</div>
		</Section>
	)
}

export default KeyDriversSection
