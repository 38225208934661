import R from "client/routes/R"
import Button from "components/Button"
import Section from "components/Section"
import React from "react"
import { useNavigate } from "react-router"

const DriversSection = (): JSX.Element => {
	const navigate = useNavigate()

	return (
		<Section dark>
			<div className="justify-between sm:flex">
				<div className="flex flex-col justify-between items-start sm:w-1/3">
					<h1 className="text-white text-opacity-30">DRIVERS</h1>
					<div className="hidden mt-8 sm:block">
						<Button title="GO TO DRIVERS" onClick={() => navigate(R.drivers)} />
					</div>
				</div>
				<div className="mt-8 sm:w-2/3 sm:mt-0">
					<p className="text-2xl text-white text-opacity-30">
						Visualise a thorough exploration of key drivers and their impact on
						the future, ordered by perceived importance and impact.
					</p>
				</div>

				<div className="mt-8 sm:hidden">
					<Button title="GO TO DRIVERS" onClick={() => navigate(R.drivers)} />
				</div>
			</div>
		</Section>
	)
}

export default DriversSection
