import React from "react"

const Line = ({
	className,
	direction,
}: {
	className?: string
	direction: "horizontal" | "vertical"
}): JSX.Element => (
	<div
		className={`border-thin-line ${
			direction === "vertical"
				? "fixed border-l top-0 bottom-0"
				: "absolute border-b right-0 left-0"
		} ${className ?? ""}`}
	/>
)

export default Line
