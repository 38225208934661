import Section from "components/Section"
import React from "react"

const ClimateSection = (): JSX.Element => (
	<Section>
		<div className="lg:flex">
			<div className="mx-auto w-3/4 lg:w-2/3 lg:px-4">
				<img className="mx-auto" src={require("./images/climateDriver.svg")} />
			</div>

			<div className="mt-18 lg:w-1/3 lg:mt-0">
				<h1>Climate Change as a Universal Driver</h1>
				<p className="my-8 lg:my-11">
					One driver of change explored in this project sits above all others,
					based on the views of contributors—climate change. Unique among the
					many factors explored, it is the one issue that will touch almost all
					aspects—social demands, economic capacity, technological innovation,
					political agendas and public values.{" "}
				</p>
				<p>
					Economic and social dimensions of climate change are seen as driving
					more attention toward sustainable business models and new channels,
					and greater attention to societal awareness, costs, and impacts.{" "}
				</p>
			</div>
		</div>
	</Section>
)

export default ClimateSection
