import Helmet from "client/utils/Helmet"
import DriversSection from "components/scenarios/DriversSection"
import MainSection from "components/scenarios/MainSection"
import QuestionsSection from "components/scenarios/QuestionsSection"
import React from "react"

const Drivers = (): JSX.Element => (
	<div>
		<Helmet title="Scenarios" />
		<MainSection />
		<QuestionsSection />
		<DriversSection />
	</div>
)

export default Drivers
