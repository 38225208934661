import React from "react"

const Content = <P,>({
	background,
	className,
	children,
}: {
	className?: string
	background?: string
} & React.PropsWithChildren<P>): JSX.Element => (
	<div className={`${background ?? ""}`}>
		{/* The relative is required because lines are `fixed` or `absolute` and the only way for any content to be above a non-`static` element is to also have a position. */}
		<div className={`relative max-w-7.5xl ml-auto mr-auto ${className ?? ""}`}>
			<div className="px-8 md:px-12 lg:px-18 xl:px-28">{children}</div>
		</div>
	</div>
)

export default Content
