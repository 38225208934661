import ScrollForMore from "components/ScrollForMore"
import Section from "components/Section"
import React from "react"

const MainSection = (): JSX.Element => (
	<Section dark>
		<div>
			<h1>About Future of Arts & Culture</h1>

			<div className="mt-16 md:mt-20 lg:mt-24">
				<h2>What is the future of arts & culture project</h2>
				<div className="mt-6 border-t md:mt-10 border-theme">
					<div className="mt-6 md:flex">
						<p className="pr-6 text-2xl md:text-xl md:w-1/2 lg:w-2/3 lg:text-2xl">
							Future of Arts and Culture is a qualitative probe into the future
							of arts and culture globally, examining emerging forces, drivers
							of change and the possible impacts that may reshape the cultural
							sector in the coming decade.
						</p>
						<p className="mt-4 md:w-1/2 lg:w-1/3 md:mt-0">
							The project was created as a collaboration among futurists, sector
							experts and stakeholders. It emerged as a way to see past the fog
							of global pandemic, to better understand how existing and emerging
							pressures, innovations and models of culture might unfold — to
							understand different ways in which creators, institutions and
							audiences might come together as a results of these shifting
							forces, and what these changes may mean for all aspects of art and
							culture ecosystems.
						</p>
					</div>
				</div>
			</div>

			<div className="flex justify-end mt-12">
				<ScrollForMore />
			</div>
		</div>
	</Section>
)

export default MainSection
