import Helmet from "client/utils/Helmet"
import MainSection from "components/about/MainSection"
import MethodologySection from "components/about/MethodologySection"
import PeopleSection from "components/about/PeopleSection"
import React from "react"

const About = (): JSX.Element => (
	<div>
		<Helmet title="About the project" />
		<MainSection />
		<MethodologySection />
		<PeopleSection />
		{/* <FuturescaperSection /> */}
	</div>
)

export default About
