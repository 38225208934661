type Scenario = {
	id: number
	title: string
	headlines: string[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	image: any
	paragraphs: string[]
}

export const scenarios: Scenario[] = [
	{
		id: 0,
		title: "Spectaculars and Small Stages",
		headlines: [
			"A walkable immersive exhibit of early French photography showcases new spatial projection tech.",
			"The world’s largest volumetric performance space opens in the China, with 500K square metres for epics.",
			"Over one dozen city museums have closed their doors this decade.",
		],
		paragraphs: [
			"With growing uncertainty around the sources of financial support they’ve traditionally relied on, arts and culture organisatiions focus on business models and the need for stable funding. Continued swings in travel, tourism and business access have made near-term profitability a continued priority, and lowered the level of risk organisations and funders are willing to accept. In this future, making an impact means generating a positive bottom line first and foremost.",
			"For many institutions, this translates to putting scarce resources into blockbusters—either developing, acquiring, or hosting must-see touring shows, well known masterpieces or big name draws with more predictable ROI.",
			"Arts organisations have to experiment and innovate around delivery models, and spend to support them, which gives the advantage to larger platforms with greater reach. This increasingly imperils mid-sized institutions, which find it harder to compete for audiences and funding, where smaller ones can lean on direct community ties at smaller scale.",
			"Creators looking to feed audiences seeking more intimate connection to art and performance push the boundaries with raw, direct and emotional works that tap both local and universal themes as a way of pushing back against what some see as a dilution of cultural experience.",
			"For the dominant big names, investment in continues in sharable “social” cultural experiences, as general audiences demand easy access to popular culture in social spaces.",
			"This trend pushes arts and culture ever further toward the dynamics of media and entertainment, favouring big names and merchandisable experiences over emerging artists and new, less familiar works.",
			"The cycle of increasing private investment and dwindling availability of public funding shifts footing to a more commercial basis longer term. While it changes the nature of cultural experiences for large audiences, it is also training new talent on new tools and technologies, which may yet trickle down to feed innovative works and delivery models for smaller venues over time.",
		],
		image: require("./images/stages.svg"),
	},
	{
		id: 1,
		title: "Global network of communities",
		headlines: [
			"Network of local museums of indigenous art stages a simultaneous global opening.",
			"The Tempest, staged in a partially submerged South Beach Miami.",
			"Cooperative stages Alternative Salzburg Festival of Colour",
		],
		paragraphs: [
			"The stress of the pandemic has created a new focus on the need to strengthen communities, a critical piece of which is the strengthening of arts and culture at a local level as a critical source of connection and growth. As larger institutions face greater pressure for commercial profitability, smaller and more regional organizations innovate using the tools and approaches adopted during the pandemic, as well as leveraging digital channels to draw communities regardless of location.",
			"This re-dedication to social impact means a greater focus on the critical issues of the day—climate change, equity and equality, social and economic justice and more. There is also greater experimentation with taking art and culture out of the studios, galleries, museums and performance spaces and embedding it within other community contexts.",
			"Expansion of creative production widens not only the number and diversity of producers, but allows new stories to be told, and bolder experimentation in telling them. Gen Z and Gen Alpha consumers, who have grown up accessing global stories via digital channels and supporting a diverse set of niche economies.",
			"New funding models, ranging from more sophisticated crowdfunding to public culture taxes in some regions, and new financial and organizational models. More inclusive, mature digital channels and new funding support enables new cultural hubs to take root in unexpected places—not just in the usual areas, but building them in rural communities.",
			"With public funding increasingly scarce and greater competition for other funding sources, communities are able to tap more innovative economic models emerging as a by-product of developments in DAOs (distributed autonomous organizations) in the crypto art world, alternative shareholder structures, cooperatives and other new models that enable greater community participation. Smaller groups are also able to develop and strengthen regional and international alliances and exchanges.",
			"More direct ties to diverse communities also helps drive an expansion of themes and issues represented in new works by a new generation of artists and performers. Communities can see themselves reflected in more inclusive shows, further cementing the relationship between creators and a wide range of audiences.",
		],
		image: require("./images/communities.svg"),
	},
	{
		id: 2,
		title: "Platforms and multiverses",
		headlines: [
			"With Ethereum 5, Art for Energy Project encourages buyers to acquire art as means of generating clean power, not consuming it.",
			"Museum in India closes physical operations to become an online museum for global diaspora.",
			"Online Stockhausen hackathon produces over 700 variations of his major works on the blockchain.",
		],
		paragraphs: [
			"Rising to the challenges of an uncertain world, a new generation of creators build on the foundations created in the early 2020s for a more transactional future where digital tools and platforms dominate attention and cultural commerce.",
			"In response to the new ways of consuming culture they’ve come of age with, and the constraints of the pandemic, these creators construct a larger universe of new outlets and direct channels for interactions, as collectives and more empowered individuals.",
			"With more direct connection to audiences, art and culture are pulled in a more pop—and populist—direction, and new categories of performers and patrons emerge. In this cultural economy, creators have new tools with which to control more of their own works, and the economic models in which they participate. New support platforms and ways of connecting cultural stakeholders as creative shareholders alter the funding equation and channel more new patrons toward micro funding culture",
			"Greater digital availability broadens new audiences, sparking demand from new categories of audiences and stretching the definition of what art and culture can be. This also further breaks the domination of the historical canon of (mostly western) works. ",
			"A far broader definition of what constitutes art emerges, unsettling traditionalists, but more formally recognizing a wider spectrum of works as valuable culture. This includes a broader definition of curation, reaching into new forms of digital artifacts, micro-heritage and rapid response collecting, expanded cultural R&D.",
			"One challenge created by these new digital platforms, however, is that for audiences accustomed to traditional exhibition and performance—in physical galleries, halls, festivals or museums, these new works are less accessible to those lacking digital skills. More hybrid productions emerge to help bridge both the digital gap, and to push older, more familiar works into new spaces and forms.",
			"In the shift to digital space, “community” takes on less geographic connotations, cultural borders are less constraining, and social value of art emerges as much from its innovative capacity as from focus on specific issues or themes.",
		],
		image: require("./images/multiverses.svg"),
	},
]

export default Scenario
