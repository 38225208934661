import React from "react"

const Copyright = (): JSX.Element => (
	<div className="flex flex-wrap justify-between mt-24 mb-12 font-medium text-xxs sm:text-xs">
		<div>© Therme Group {new Date().getFullYear()}</div>
		<div className="2xs:hidden">A study by Changeist</div>
		<div className="hidden 2xs:block">developed by ARUP x THERME GROUP</div>
		<div className="hidden mt-2 w-full text-center 2xs:mt-0 2xs:w-auto 2xs:block">
			A study by Changeist
		</div>
		<div className="mt-2 w-full text-center 2xs:hidden 2xs:mt-0 2xs:w-auto">
			developed by ARUP x THERME GROUP
		</div>
	</div>
)

export default Copyright
