import React from "react"

const AboutSection = (): JSX.Element => (
	<>
		<h2 className="mt-18">About the Scenarios</h2>

		<div className="mt-8 border-t lg:mt-10 border-theme">
			<div className="lg:mt-5 lg:flex">
				<p className="pr-6 mt-6 lg:w-1/3 lg:mt-0">
					Scenarios as an exploratory form emerged from collaboration between
					science and art—specifically between strategic research, filmmaking
					and storytelling. These scenarios are true to that approach,
					expressing a collection of related data through familiar, human-scale
					stories.
				</p>
				<p className="pr-6 mt-6 lg:w-1/3 lg:mt-0">
					Those presented here are not meant to be forecasts as much as
					provocations that interweave the collective opinions and insights of
					the global participant group. They are possible futures as
					collectively voiced by contributors, and the narratives that follow
					connect the dots of data in narrative forms that can be more easily
					explored.
				</p>
				<div className="pr-6 mt-6 lg:w-1/3 lg:mt-0">
					<p>
						They are also not mutually exclusive futures; any given
						organisation, large or small, global or local, may be confronting
						aspects of each of these scenarios on different timescales.
					</p>
				</div>
			</div>
		</div>
	</>
)

export default AboutSection
