import TextButton from "components/TextButton"
import React from "react"

const ScrollForMore = (): JSX.Element => (
	<TextButton
		className="cursor-default"
		icon="chevron-down"
		iconSide="right"
		text="Scroll for more"
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onClick={() => {}}
	/>
)

export default ScrollForMore
