import R from "client/routes/R"
import Button from "components/Button"
import ScrollForMore from "components/ScrollForMore"
import Section from "components/Section"
import React from "react"
import { Link } from "react-router-dom"

const Element = ({ text, value }: { text: string; value: number }) => (
	<div className="w-28 uppercase border-t border-theme">
		<div className="flex flex-col pt-4">
			<h2>{value}</h2>
			<p>{text}</p>
		</div>
	</div>
)

const MainSection = (): JSX.Element => (
	<Section>
		<div className="flex">
			<div className="w-2/3 z-1">
				<h1 className="-ml-1 text-6xl font-medium md:text-7xl">
					Future of Arts & Culture
				</h1>
				<div className="-ml-0.25 text-xl md:text-2xl font-normal uppercase md:mt-0 mt-4">
					<span className="font-medium">Drivers</span> and{" "}
					<span className="font-medium">Impacts</span> for the Next Decade
				</div>
			</div>
			<div>
				<img
					className="absolute top-0 sm:-top-12 lg:top-0 right-[7%] lg:right-[8.5%] origin-top-right lg:scale-[0.52] scale-[0.7] md:scale-[0.6] z-0 opacity-80 lg:opacity-100"
					src={require("./images/main.svg")}
				/>
			</div>
		</div>

		<div className="mt-12 w-full md:w-1/2 lg:w-1/3 md:mt-18">
			<div className="flex justify-between mb-5">
				<Element text="Scenarios" value={52} />
				<Element text="Sessions" value={204} />
				<Element text="Factors" value={508} />
			</div>
			<p>
				A study based on 300 participants, leaders in museums, galleries,
				performing arts, music, funding organizations, curators and producers,
				from North America, South America, Europe, Asia, Middle East, Africa.
			</p>
		</div>

		<div className="flex flex-row justify-between items-center mt-18 lg:mt-48">
			<Link to={R.event}>
				<Button
					className="text-[11px] md:text-[12px] px-2 sm:px-6 text-center min-w-[6rem] sm:min-w-[10rem]"
					title="SEE THE PUBLIC LAUNCH"
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onClick={() => {}}
				/>
			</Link>
			<ScrollForMore />
		</div>
	</Section>
)

export default MainSection
