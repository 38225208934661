import Helmet from "client/utils/Helmet"
import ClimateSection from "components/drivers/ClimateSection"
import KeyDriversSection from "components/drivers/KeyDriversSection"
import MainSection from "components/drivers/MainSection"
import React from "react"

const Drivers = (): JSX.Element => (
	<div>
		<Helmet title="Drivers" />
		<MainSection />
		<ClimateSection />
		<KeyDriversSection />
	</div>
)

export default Drivers
