import serialize from "serialize-javascript"
import { isNonEmptyString } from "server/lib/validations"

const TOKEN_KEY = "40627dfa2e3ea05c134584cccd0a1af4"

// Yay SSR.
const dummyStorage = {
	getItem: function (_: string) {
		return null
	},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setItem: function () {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	clear: function () {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	removeItem: function () {},
	isSSR: true,
}
const local = typeof localStorage === "undefined" ? dummyStorage : localStorage
const session =
	typeof sessionStorage === "undefined" ? dummyStorage : sessionStorage

const storage = {
	isSSR(): boolean {
		return local.isSSR === true
	},

	isAuthenticated(): boolean {
		return isNonEmptyString(this.getJWT())
	},

	/**
	 * Remove an item from the used storage
	 */
	remove(key: string): void {
		try {
			local.removeItem(key)
			session.removeItem(key)
		} catch {}
	},

	/**
	 * Remove all app storage
	 */
	clearAppStorage(): void {
		try {
			local.clear()
			session.clear()
		} catch {}
	},

	removeJWT(): void {
		this.remove(TOKEN_KEY)
	},

	/**
	 * Set data in storage
	 * @param fromLocalStorage Defines if we need to store in `localStorage` or `sessionStorage`.
	 */
	get(key: string, fromLocalStorage = true): unknown {
		try {
			if (fromLocalStorage) {
				const value = local.getItem(key)

				if (isNonEmptyString(value)) {
					return JSON.parse(value)
				}

				return undefined
			}

			const value = session.getItem(key)

			if (isNonEmptyString(value)) {
				return JSON.parse(value)
			}

			return undefined
		} catch {
			return undefined
		}
	},

	getJWT(): string | undefined {
		const token = this.get(TOKEN_KEY, true)

		if (isNonEmptyString(token)) {
			return token
		}

		return undefined
	},

	/**
	 * Set data in storage
	 * @param isLocalStorage Defines if we need to store in `localStorage` or `sessionStorage`.
	 */
	set(value: unknown, key: string, isLocalStorage = true): void {
		const toWrite = serialize(value)

		try {
			if (isLocalStorage) {
				local.setItem(key, toWrite)
				return
			}

			session.setItem(key, toWrite)
		} catch {}
	},

	setJWT(value: string): void {
		this.set(value, TOKEN_KEY, true)
	},
}

export default storage
