import Content from "components/Content"
import React from "react"

const Section = <P,>({
	className,
	dark = false,
	...props
}: {
	className?: string
	dark?: boolean
} & React.PropsWithChildren<P>): JSX.Element => (
	<Content
		className={`overflow-visible py-12 md:py-18 lg:py-24 xl:py-36 ${
			className ?? ""
		}`}
		background={`${dark ? "bg-dark" : ""}`}
		{...props}
	/>
)

export default Section
