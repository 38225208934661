import ScrollForMore from "components/ScrollForMore"
import Section from "components/Section"
import React from "react"

const Element = ({
	icon,
	title,
	body,
}: {
	icon: React.ReactChild
	title: string
	body: React.ReactChild
}) => (
	<div className="pr-6 mt-12 lg:w-1/3 lg:mt-0">
		<div className="h-12">{icon}</div>
		<div>
			<h2 className="lg:text-xl xl:text-2xl pt-4 mt-6 lg:mt-10 border-t border-current lg:h-[90px]">
				{title}
			</h2>
			<div className="mt-4 lg:mt-0">{body}</div>
		</div>
	</div>
)

const MainSection = (): JSX.Element => (
	<Section dark>
		<div className="sm:flex">
			<h1 className="sm:w-1/2 lg:w-2/3">Main Driver Categories</h1>
			<p className="mt-8 text-2xl opacity-30 sm:w-1/2 lg:w-1/3 sm:mt-0">
				Three key groups of drivers emerged from over 500 inputs.
			</p>
		</div>

		<div className="w-4/5 mt-18 lg:flex lg:w-full lg:mt-20">
			<Element
				icon={<img src={require("./images/chart.svg")} />}
				title="Business models and ecosystems"
				body={
					<ul>
						<li>Commercial pressures and impact measures</li>
						<li>
							Uncertain environment (recognition of climate, COVID-19, other X
							factors)
						</li>
					</ul>
				}
			/>
			<Element
				icon={<img src={require("./images/account.svg")} />}
				title="Changing creators audiences and values"
				body={
					<ul>
						<li>New tastes, tools, interests and habits</li>
						<li>New forms of creation and expertise develop</li>
					</ul>
				}
			/>
			<Element
				icon={<img src={require("./images/graph.svg")} />}
				title="Social and climate issues"
				body={
					<ul>
						<li>Equity, diversity and representation</li>
						<li>Move beyond Western canon of art and culture</li>
					</ul>
				}
			/>
		</div>

		<div className="flex justify-end mt-10">
			<ScrollForMore />
		</div>
	</Section>
)

export default MainSection
