import Helmet from "client/utils/Helmet"
import Scenario from "components/scenarios/Scenario"
import ScrollForMore from "components/ScrollForMore"
import Section from "components/Section"
import React from "react"

const ScenarioView = ({ scenario }: { scenario: Scenario }): JSX.Element => (
	<div>
		<Helmet title={scenario.title} />
		<Section dark>
			<div className="text-3xl lg:text-4xl xl:text-5xl">
				Scenario {scenario.id + 1}:{" "}
				<span className="font-bold uppercase text-theme">{scenario.title}</span>
			</div>
			<div>
				<h2 className="mt-24">Headlines from this future</h2>
			</div>
			<div className="content-between mt-10 lg:flex">
				{scenario.headlines.map((h, i) => {
					return (
						<div className="pr-6 mt-10 lg:w-1/3 lg:mt-0" key={i}>
							<p className="pt-5 text-2xl border-t border-theme">{h}</p>
						</div>
					)
				})}
			</div>
			<div className="flex justify-end mt-12 md:mt-24 lg:mt-36">
				<ScrollForMore />
			</div>
		</Section>

		<Section>
			<img src={scenario.image} alt={scenario.title} />
		</Section>

		<Section dark>
			<div className="lg:flex">
				<h2 className="lg:w-1/3">Description</h2>
				<div className="mt-8 lg:w-2/3 lg:mt-0">
					{scenario.paragraphs.map((p, i) => {
						return (
							<p className="pt-6 first:pt-0" key={i}>
								{p}
							</p>
						)
					})}
				</div>
			</div>
		</Section>
	</div>
)

export default ScenarioView
