const keyDrivers = [
	{
		title: "Social value and wellness",
		body: "This represents a shift away from art as entertainment toward art and culture as impact medium.\n\nClimate casts a long shadow as a creative subject and object of awareness and action.",
	},
	{
		title: "Value of art & culture",
		body: "Diminishing breadth of artistic and cultural exploration is anticipated as business models shift, audiences’ tastes change, and funding focuses on bigger hits.",
	},
	{
		title: '"Impact" as investment lens',
		body: 'Accelerated tension between "impact" as an economic measure, and as a social measure.\n\nHow this is defined may become the "switch" that determines industry trajectory.',
	},
	{
		title: "Museums become more entrepreneurial",
		body: '"Entrepreneurial" in this context implies arts and culture organisations being both more agile and self-directing, yet mindful of economics.\n\nIn the future, institutions will be expected to innovate even more than today.',
	},
	{
		title: "Equity and inclusivity",
		body: "Generational change, and the tastes and values that come with it, will be a significant shaping force in the next decade.\n\nThis shift will include new creators, with diverse visions and talents, and audiences’ interests and expectations may also shift more dramatically to new forms.",
	},
	{
		title: "New generation of creators",
		body: "A new generation of artists and creators are likely to bring with them arts and culture that depict new frames of social change, with new lenses on emerging and enduring issues.\n\nThis may again stretch the definitions of what constitutes arts and culture, with new forms and genres, new ways of “performing” and interacting with creative works.",
	},
	{
		title: "Changing audiences",
		body: "The generational shift brings with it a feedback loop. Broader definitions of culture and a more diverse canon inform new audience interests and expectations.\n\nThis, in turn, widens channels and audiences but may reposition culture as something more populist, diverse, and even mundane as it blends with media, entertainment and other forms of culture.",
	},
	{
		title: "Decreased value of art and culture",
		body: "Longer term, the expansion — and potential dilution — of the boundaries of arts and culture may lead to both a new, privatized elitism, and new undergrounds to stir creativity.\n\nIn the meantime, mainstream cultural economies become increasingly privatized.",
	},
	{
		title: "Diversification from Western Canon",
		body: "Critically, a broadening of the canon of arts and culture, away from dominant Western works, is seen as pushing arts and culture into new spheres, changing both definitions and economics.\n\nDown this path, arts and culture may attract new funders who are eager to back new works for broader audiences, helping to fuel experimentation.",
	},
	{
		title: "New definitions of expertise",
		body: "Greater diversity among creators, supported by diverse leaders in the arts, may recognize new forms of expertise, telling stories about communities they emerge from or are embedded within.\n\nThis fresh infusion of diversity helps drive new experimentation across different disciplines, reinvigorating arts and culture at the grassroots.",
	},
	{
		title: "Attracting diverse audiences",
		body: "Social and economic imperatives drive production of works which draw wider, more diverse audiences, potentially breaking down barriers to art and culture created by elitist perceptions.",
	},
	{
		title: "Hybrid event models",
		body: "Driven to explore more innovative models and embrace creative R&D as a core capability, more organizations continue to explore hybrid models of delivery post-pandemic, blurring the lines between performance, play, experience, production and other activities.",
	},
	{
		title: "New modes of consumption",
		body: "New forms of delivery create a virtuous cycle of demand for new ways of experiencing art and culture, while expanding engagement to more aspects of our lives. More playful engagement enables more frequent casual interactions with art and culture outside traditional settings.",
	},
]

export default keyDrivers
