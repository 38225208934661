import Section from "components/Section"
import React from "react"

const Person = ({
	name,
	title,
	subtitle,
	image,
}: {
	name: string
	title: string
	subtitle?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	image: any
}): JSX.Element => (
	<div className="flex items-center pr-4">
		<img className="w-32 h-32" src={image} />
		<div className="ml-6">
			<h3>{name}</h3>
			<div>{title}</div>
			<div>{subtitle}</div>
		</div>
	</div>
)

const PeopleSection = (): JSX.Element => (
	<Section dark>
		<div className="grid grid-cols-1 gap-y-10 lg:grid-cols-3 md:grid-cols-2 lg:gap-y-12">
			<h2>Project initiators</h2>
			<Person
				name="Tateo Nakajima"
				title="Fellow, Arts, Culture,"
				subtitle="Arup, UK"
				image={require("./images/nakajima.png")}
			/>
			<Person
				name="Annette Mees"
				title="Artistic Director Audience Labs,"
				subtitle="Visiting Senior Research Fellow in Culture and Creative Industries at King’s College London, UK"
				image={require("./images/mees.png")}
			/>
			<Person
				name="Honor Harger"
				title="Executive Director,"
				subtitle="ArtScience Museum, Singapore"
				image={require("./images/harger.png")}
			/>
			<Person
				name="Dr. Robert C. Hanea"
				title="Chairman and CEO,"
				subtitle="Therme Group, Germany"
				image={require("./images/hanea.png")}
			/>
			<Person
				name="Scott Smith"
				title="Founder and Managing Partner,"
				subtitle="Changeist, The Netherlands"
				image={require("./images/smith.png")}
			/>
		</div>
	</Section>
)

export default PeopleSection
